.trademarklogos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  filter: grayscale(90%);
}

$animation-time: 0.4s; // Set time of the animation

.trademarklogo {
  flex: 0 0 auto;
  max-width: 25%;
  margin-right: 20px;
  animation: floatAnimation 4s infinite alternate ease-in-out;
  transition: transform $animation-time ease;
}

// Not friggin working
#trademarklogos img:hover {
  filter: grayscale(0%);
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

$logo-count: 9; // Set the total number of logos

@for $i from 1 through $logo-count {
  .trademarklogo:nth-child(#{$i}) {
    animation-delay: calc($i * $animation-time); // Apply animation delay based on index
  }
}

/* Add more nth-child selectors with different delays for additional logos */

